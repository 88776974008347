<template>
  <v-app style="background-color: #a7d1d9">
    <v-main>
      <v-container fill-height class="py-16 pt-16">
        <v-layout align-center justify-center class="d-flex flex-row-reverse flex-wrap">
          <div class="rightBox d-flex flex-row">
            <v-flex xs12 sm12 md8 class="loginBox my-auto mx-auto d-flex flex-column">
              <!-- <div class="pb-10 d-flex pa-2 justify-center">
                     <v-img
                       lazy-src="../assets/img/lucid-logo.png"
                       max-height="350"
                       max-width="150"
                       src="../assets/img/lucid-logo.png"
                     ></v-img>
                   </div> -->
              <!-- <div > -->
              <div class="text-h4 mb-12 mx-auto">
                <v-img src="@/assets/img/lucid.png" width="180" :contain="true"></v-img>
              </div>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-label class="caption">Username</v-label>
                <v-text-field outlined dense :elevation="0" v-model="username" required :rules="[(v) => !!v || 'Username is required!']" name="username" type="text" v-on:keyup.enter="login()"></v-text-field>

                <v-label class="caption">Password</v-label>
                <v-text-field
                  outlined
                  dense
                  v-model="password"
                  name="password"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[(v) => !!v || 'Password is required!']"
                  required
                  v-on:keyup.enter="login()"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
                <v-btn :disabled="!valid" class="mt-4 mb-4 px-8" large @click="login()" color="primary" value="log in" block>Login</v-btn>
                <div class="caption grey--text text--darken-2">By continuing, you agree to <a :href="termsAndConditionsUrl" target="_blank" >Lucid Genomics User Agreement</a> and the <a :href="PrivacyPolicyUrl" target="_blank" >Privacy Notice</a> </div>
              </v-form>
              <div v-if="!isAllowedBrowser || isUpdatedBrowser || isDesktop" class="caption orange--text text--darken-3">
                <div v-if="!isAllowedBrowser">
                  <v-icon class="mr-1" small color="orange">mdi-alert-outline</v-icon>
                  For an optimal experience, please use Google Chrome or Firefox.</div>
                <div v-if="!isUpdatedBrowser && isAllowedBrowser">
                  <v-icon class="mr-1" small color="orange">mdi-alert-outline</v-icon>
                  You are using an outdated version of {{ deviceInfo?.browser }} ({{ deviceInfo?.browserVersion }}). For your security and the best experience, we strongly recommend updating your browser to the latest version.
                </div>
                <div v-if="!isDesktop && isAllowedBrowser">
                  <v-icon class="mr-1" small color="orange">mdi-alert-outline</v-icon>
                  For the best experience, we recommend using a desktop or larger screen device, as the current setup may not provide optimal functionality.</div>
              </div>
              <!-- </div> -->
            </v-flex>
          </div>
          <div class="leftBox d-flex flex-column">
            <div class="mt-10 mx-auto">
              <v-img src="@/assets/img/dna-amico.svg" width="600" :contain="true"></v-img>
            </div>
            <div class="text-h3 font-weight-bold mx-10" style="color: #038497">Lucid Genomics</div>
            <div class="text-h5 font-weight-bold mb-auto mx-10" style="color: #777777">AI-driven solutions making the entire genome actionable</div>
          </div>
        </v-layout>
      </v-container>
    </v-main>
    <div class="mx-auto caption primary--text mb-1"><a class="caption text-center" href="https://storyset.com/medical">Medical </a> & <a class="caption text-center" href="https://storyset.com/people">People </a>illustrations by Storyset</div>
  </v-app>
</template>

<script>
import { authenticate, getUser } from "@/api/users";
// import TermsAndConditions from "../components/TermsAndConditions.vue";
var browser = require("browser-tool");
// import { authenticate  } from "@/api/users";
export default {
  name: "Login",
  data() {
    return {
      valid: true,
      showPassword: false,
      username: "",
      password: "",
      confirmPassword: "",
      errorMessage: "",
      deviceInfo: null,
      acceptedBrowsers: {
        //browser-update.org
        Chrome: 126,
        Firefox: 126,
      },
    };
  },
  async created() {
    // console.log(browser);
    this.deviceInfo = await browser.default.getInfo(["system", "browser", "device"]);
  },
  methods: {
    login() {
      var isValidated = this.$refs.form.validate();
      if (isValidated) {
        const _this = this;
        authenticate(
          this.username,
          this.password,
          function (res) {
            _this.$root.notify.show({ message: "Welcome " + _this.username + "!" });

            _this.$store.commit("login", res);

            getUser(res.id, (r) => {
              res["email"] = r.email;
              res["first_name"] = r.first_name;
              res["last_name"] = r.last_name;
              res["organization"] = r.organization;
              _this.$store.commit("login", res);
              if (_this.$route.query.redirect != null && res.is_staff === false) {
                _this.$router.push(_this.$route.query.redirect);
              } else {
                if (res.is_staff === true) {
                  _this.$router.push("/admin");
                } else {
                  _this.$router.push("/user/projects");
                }
              }
            });
          },
          function (err) {
            console.log(err);
            if (err.status == 429 && (err?.data?.Reason || null) == "TOO_MANY_ATTEMPTS") {
              _this.$root.notify.show({ message: "Number of failed login attempts exeecded! You can try again in 15 minutes", type: "error" });
            } else {
              _this.$root.notify.show({ message: "Invalid username or password", type: "error" });
            }
          }
        );
      }
      //  const _this = this

      //this.errorMessage = "password did not match"
    },
  },
  computed: {
    termsAndConditionsUrl(){
      return this.$router.resolve({ name: "PublicTermsAndConditions" }).href;
    },
    PrivacyPolicyUrl(){
      return this.$router.resolve({ name: "PublicPrivacyPolicy" }).href;
    },


    
    isAllowedBrowser() {
      return !!this.deviceInfo && Object.keys(this.acceptedBrowsers).includes(this.deviceInfo.browser);
    },
    isUpdatedBrowser() {
      return this.isAllowedBrowser && Number(this.deviceInfo.browserVersion.substr(0, this.deviceInfo.browserVersion.indexOf("."))) >= this.acceptedBrowsers[this.deviceInfo.browser];
    },
    isDesktop() {
      return !!this.deviceInfo && this.deviceInfo.device == "Desktop";
    },
  },
};
</script>

<style scoped lang="scss">
.leftBox {
  /* border:  1px solid red; */
  border-radius: 50px 0px 0px 50px;
  background-color: #e3f1f3;
  height: 100%;
  width: 50%;
  min-width: 500px;
}
.rightBox {
  border-radius: 0px 50px 50px 0px;
  background-color: #f4f9fa;
  height: 100%;
  min-width: 500px;
  width: 50%;
}

.loginBox {
  // border: 1px solid var(--v-primary-base);
  // border-radius: 8px;
  padding: 20px;
}
@media only screen and (max-width: 1265px) {
  .leftBox {
    border-radius: 0px 0px 50px 50px;
    height: 300px;
  }
  .rightBox {
    /* border:  1px solid red; */
    border-radius: 50px 50px 0px 0px;
    height: auto;
  }
}
</style>